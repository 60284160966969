<template>
  <div>
    <header class="header" :style="isMemberHeader ? 'border-bottom: 0' : ''">
      <div :class="isMemberHeader ? 'header__content member' : 'header__content'">
        <div class="logo">
          <router-link :to="{path: '/'}"><img src="../../assets/images/logo@2x.png" alt=""></router-link>
        </div>
        <nav class="nav" v-if="!isMemberHeader">
          <ul>
            <li :class="$route.path === item.path ? 'active' : ''" :key="index" v-for="(item, index) in navList">
              <a v-if="item.path.indexOf('http') >= 0" :href="item.path" target="_blank">{{ item.name }}</a>
              <router-link v-else :to="{path: item.path}">{{ item.name }}</router-link>
            </li>
          </ul>
        </nav>
        <div v-if="userName" class="user-login-info" style="cursor:pointer">
          <el-dropdown trigger="click">
            <a class="message"></a>
            <el-dropdown-menu slot="dropdown" class="message-dropdown">
              <div class="message__content">
                <div class="message__content__top">
                  <el-button type="text">通知</el-button>
                  <el-button type="text" style="color: #4E5969;">消息({{ userInfo.unread_count }})</el-button>
                </div>
                <el-dropdown-item>
                  <ul>
                    <li v-for="item in userInfo.notifys" :key="item.id">
                      <div>
                        <router-link :to="{ path: '/member/notify-detail', query: {id: item.id} }">
                          <span v-if="item.read_status ===  false">{{ item.title }}</span>
                          <span v-else style="color:#777">{{ item.title }}</span>
                        </router-link>
                      </div>
                      <p>{{ item.created_at }}</p>
                    </li>
                  </ul>
                </el-dropdown-item>
                <el-dropdown-item>
                  <div class="message__content__bottom">
                    <el-button type="text" @click="readAll">全部已读</el-button>
                    <el-button type="text" @click="$router.push({ path: '/member/notify' })">查看更多</el-button>
                  </div>
                </el-dropdown-item>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown trigger="click" v-if="!isMemberHeader">
            <router-link :to="{ path: '/member/index' }">
              <div class="avatar-wrapper">
                <i v-if="userName">{{ userName.substring(1, 0) }}</i>
                <span style="color: #282938">{{ userName }}</span>
              </div>
            </router-link>
          </el-dropdown>

          <el-dropdown trigger="click" v-else>
            <div class="avatar-wrapper">
              <i v-if="userName">{{ userName.substring(1, 0) }}</i>
              <span style="color: #282938">{{ userName }}</span>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="logOut">
                <span>退出登录</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div v-else>
          <router-link :to="{ path: '/login' }" class="el-button header__btn el-button--primary el-button--small">
            登录/注册
          </router-link>
        </div>
      </div>
    </header>
    <div class="header-placeholder"></div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import request, {handleCloseLoading, successCode, tokenName} from "@/utils/request";

export default {
  name: 'HeaderComponent',
  props: {
    isMemberHeader: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      navList: [
        {path: '/', name: '首页'},
        {path: 'https://shouuba.com/docs/', name: '接入文档'},
        {path: '/fqa', name: '常见问题'},
        {path: '/online-test', name: '在线测试'},
        {path: '/contact-us', name: '联系我们'}
      ]
    }
  },
  computed: {
    ...mapGetters([
      'userInfo',
    ]),
    userName: function () {
      const userInfo = this.$store.state.user.userInfo
      return userInfo ? userInfo.name : null
    },
  },
  methods: {
    async logOut() {
      const token = window.localStorage.getItem(tokenName)
      if (token) {
        const status = await this.$store.dispatch('Logout', token)
        if (status) {
          await this.$router.push({path: '/'})
        }
      }
    },
    async readAll() {
      try {
        const {code} = await request({
          url: '/api/notify/read-all',
          method: 'get',
          showLoading: true
        })

        handleCloseLoading()

        if (code === successCode) {
          this.$message({
            message: '操作成功',
            type: 'success'
          });

          // 把通知状态全部修改
          this.$store.commit('READ_ALL')
          return
        }
      } catch (e) {
        handleCloseLoading()
      }

      this.$message.error('操作失败');
    },
  }
}
</script>

<style lang="scss" scoped>
.header-placeholder {
  height: 61px;
}

.header {
  border-bottom: 1px solid rgba(79, 152, 238, 0.15);
  background: #FFFFFF;
  position: fixed;
  width: 100%;
  z-index: 1002;
  left: 0;
  top: 0;

  .logo {
    img {
      width: 90px;
      height: 32px;
    }
  }

  &__content {
    width: 1340px;
    margin: 0 auto;
    display: flex;
    height: 60px;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;

    &.member {
      width: 100%;
      padding: 0 16px;
      box-shadow: 0 4px 10px 0 rgba(78, 89, 105, 0.06);
    }

    .user-login-info {
      display: flex;
      align-items: center;

      .message {
        background: url("../../assets/images/message.png");
        background-size: cover;
        width: 32px;
        height: 32px;
        display: inline-block;
        margin-right: 16px;
        transform: translateY(3px);
      }

      .avatar-wrapper {
        > i {
          width: 32px;
          height: 32px;
          display: inline-block;
          margin-right: 10px;
          color: #FFFFFF;
          background: #4F9BEC;
          border-radius: 50%;
          font-size: 18px;
          line-height: 32px;
          text-align: center;
          font-style: normal;
        }
      }
    }

    .nav {
      margin-left: 300px;

      ul {
        li {
          position: relative;

          &.active::before {
            content: '';
            width: 26px;
            height: 2px;
            background: #4F98EE;
            position: absolute;
            left: 50%;
            bottom: -11px;
            transform: translate(-50%);
          }

          a {
            display: inline-block;
            padding: 10px;
          }
        }
      }
    }
  }

  nav {
    ul {
      display: flex;

      li {
        margin-left: 47px;

        a {
          color: #282938;
        }
      }
    }
  }

  &__btn {
    border-color: #4F98EE;
    border-radius: 6px;
    background: #4F98EE;
  }
}

.message__content {
  width: 360px;
  display: block;

  .el-dropdown-menu__item:focus, .el-dropdown-menu__item:not(.is-disabled):hover {
    background: none;
  }

  &__top {
    height: 46px;
    font-size: 14px;
    padding: 0 16px;
    border-bottom: 1px solid #E5E6EB;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: normal;
  }

  &__bottom {
    display: flex;
    position: relative;

    > button {
      flex: 1;
      height: 50px;
    }

    &::before {
      content: '';
      width: 1px;
      height: 100%;
      background: #f2f2f2;
      left: 50%;
      top: 0;
      display: block;
      position: absolute;
    }
  }

  ul {
    line-height: 22px;
    height: 154px;
    overflow-y: auto;

    li {
      padding: 16px;
      border-bottom: 1px solid #E5E6EB;
      color: #4E5969;

      > div {
        color: #1D2129;
        font-weight: bold;
        margin-bottom: 5px;

        a {
          color: #1D2129;
        }
      }

      &.read {
        color: #91969b;

        > div {
          color: #91969b;
        }
      }
    }
  }
}

.message-dropdown {
  padding: 0;
}
</style>
