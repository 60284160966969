import request, {successCode, handleCloseLoading, tokenName} from '@/utils/request'

const user = {
    // namespaced: true,
    state: {
        userName: '',
        userInfo: {}
    },
    mutations: {
        SET_USERNAME(state, result) {
            state.userName = result.name
            // 设置 token 到本地存储
            window.localStorage.setItem(tokenName, result["token"])
        },
        UNSET_USERNAME(state) {
            state.userName = ''
            // 清除 token 到本地存储
            window.localStorage.removeItem(tokenName)
        },
        SET_USERINFO(state, result) {
            state.userInfo = result
        },
        READ_ALL(state) {
            state.userInfo.unread_count = 0
            state.userInfo.notifys = state.userInfo.notifys.map(item => {
                item['read_status'] = true

                return item
            })
        }
    },
    actions: {
        async Login({commit}, data) {
            try {
                const result = await request({
                    url: '/api/login',
                    method: 'post',
                    data: {
                        name: data.username,
                        password: data.password,
                        remember_me: data.rememberMe
                    },
                    showLoading: false
                })
                handleCloseLoading()

                if (result.code === successCode) {
                    commit('SET_USERNAME', result.data)
                    return true
                }
            } catch (e) {
                handleCloseLoading()
            }

            return false;
        },
        async Register({commit}, data) {
            try {
                const result = await request({
                    url: '/api/register',
                    method: 'post',
                    data: {
                        name: data.username,
                        password: data.password,
                        password_confirmation: data.confirmPassword,
                    },
                    showLoading: false
                })
                handleCloseLoading()

                if (result.code === successCode) {
                    commit('SET_USERNAME', result.data)
                    return true
                }

                return result.message;
            } catch (e) {
                handleCloseLoading()
            }

            return false;
        },
        // eslint-disable-next-line no-empty-pattern
        async ConfirmCode({}, data) {
            try {
                const result = await request({
                    url: '/api/two-factor-authentication/confirm',
                    method: 'post',
                    data: {
                        code: data.code,
                    },
                    showLoading: false
                })
                handleCloseLoading()

                if (result.code === successCode) {
                    return true
                }

                return result.message;
            } catch (e) {
                handleCloseLoading()
            }

            return false;
        },
        async Info({commit}, showLoading = false) {
            try {
                const result = await request({
                    url: '/api/user/info',
                    method: 'get',
                    showLoading: showLoading
                })

                if (result.code === successCode) {
                    commit('SET_USERINFO', result.data)
                    handleCloseLoading()
                    return true
                }
                handleCloseLoading()
            } catch (e) {
                handleCloseLoading()
            }

            return false;
        },
        async Logout({commit}) {
            try {
                const result = await request({
                    url: '/api/logout',
                    method: 'post',
                    showLoading: true
                })
                handleCloseLoading()

                if (result.code === successCode) {
                    commit('SET_USERINFO', undefined)
                    commit('UNSET_USERNAME')
                    return true
                }
            } catch (e) {
                handleCloseLoading()
            }

            return false;
        }
    }
}

export default user
