<template>
  <div class="default-view">
    <header-component/>
    <router-view/>
    <footer-component/>
  </div>
</template>

<script>
import Header from "@/layout/components/Header";
import Footer from "@/layout/components/Footer";
import {tokenName} from "@/utils/request";

export default {
  name: 'DefaultView',
  components: {
    [Header.name]: Header,
    [Footer.name]: Footer
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData: async function () {
      const token = window.localStorage.getItem(tokenName)

      if (token) {
        await this.$store.dispatch('Info', false)
      }
    }
  }
}
</script>

<style lang="scss">
.default-view {
  min-height: 100vh;
  position: relative;
}
</style>
