<template>
  <div class="index-view">
    <el-carousel loop :interval="5000" indicator-position="none">
      <el-carousel-item class="banner-item" v-for="item in 1" :key="item" >
        <router-link :to="{ path: '/fqa' }"><img src="../../assets/images/banner.png"></router-link>
      </el-carousel-item>
    </el-carousel>
    <div class="index-view__content">
      <ul>
        <li>
          <img src="../../assets/images/jiekoufuwu.png" alt="">
          <h2>支持 USDT收款接口服务</h2>
          <div>安全稳定，秒级到账。突破传统支付收款壁垒，快速打通交易闭环。</div>
        </li>
        <li>
          <img src="../../assets/images/jisudaozhang.png" alt="">
          <h2>高效率，极速到账</h2>
          <div>得益于良好的架构设计和编程技巧，其公链的运行效率在全球公链中都名列前茅。</div>
        </li>
        <li>
          <img src="../../assets/images/tiyanhao.png" alt="">
          <h2>用户体验好</h2>
          <div>让用户付款不再望而却步，极大提升交易转换率，真正实现多边共赢。</div>
        </li>
        <li>
          <img src="../../assets/images/anquanwending.png" alt="">
          <h2>安全稳定</h2>
          <div>系统对用户和交易数据进行了最高强度的加密处理，关联的数字货币钱包也进行了物理备份。</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IndexView',
}
</script>

<style lang="scss">
.index-view {
  min-height: calc(100vh - 322px);
  display: flex;
  flex-direction: column;

  > div {
    flex: 1;

    &.el-carousel {
      background: #4F98EE;
    }
  }

  .el-carousel__container {
    height: 340px;
  }
  .banner-item {
    background: #4F98EE;
    text-align: center;
    a {
      display: block;
    }
    img {
      width: 1440px;
      height: 340px;
    }
  }

  &__content {
    width: 1340px;
    margin: 0 auto;
    //min-height: 360px;
    //height: calc(100vh - 662px);
    display: flex;
    align-items: center;
    justify-content: space-between;

    ul {
      flex: 1;
      padding: 50px 0;
      display: flex;
      justify-content: space-between;

      li {
        width: 320px;
        height: 260px;
        background: #FFFFFF;
        padding: 42px 48px;
        box-sizing: border-box;
        color: #4E5969;
        font-family: SourceHanSansCN-Regular;

        h2 {
          font-size: 18px;
          color: #282938;
          margin: 30px 0 23px;
          font-weight: bold;
        }

        > img {
          width: 54px;
          height: 54px;
        }
      }
    }
  }
}
</style>
