<template>
  <div>
    <div class="footer-placeholder"></div>
    <footer class="footer">
      <div class="footer__content">
        <div class="footer__links">
          <img src="../../assets/images/footer-logo@2x.png" alt="">
          <div>最佳 USD收款商户服务工具</div>
          <div class="icons">
            <a href="#"><img src="../../assets/images/f-icon.png" alt=""></a>
            <a href="#"><img src="../../assets/images/in-icon.png" alt=""></a>
            <a href="#"><img src="../../assets/images/fg-icon.png" alt=""></a>
            <a href="#"><img src="../../assets/images/xj-icon.png" alt=""></a>
          </div>
        </div>
        <div class="footer__about">
          <ul>
            <li><a href="#">常量参考</a></li>
            <li><a href="#">交易接口</a></li>
            <li><a href="#">钱包接口</a></li>
          </ul>
          <ul>
            <li><router-link :to="{ path: '/contact-us' }">关于我们</router-link></li>
            <li><router-link :to="{ path: '/online-test' }">在线演示</router-link></li>
            <li><router-link :to="{ path: '/fqa' }">常见问题</router-link></li>
          </ul>
        </div>
        <div class="reach-us">
          <div>Reach us</div>
          <div class="reach-us__email"><i class="el-icon-arrow-down"></i>info@TechnoMark.io</div>
        </div>
      </div>
      <div class="copyright">
        <div class="container">
          <span>Copyright © {{ new Date().getFullYear() }} Technomark. All Rights Reserved.</span>
          <span>
            <router-link :to="{ path: '/privacy-statement' }">隐私声明</router-link>|<router-link :to="{ path: '/service-agreement' }">服务协议</router-link>|<router-link :to="{ path: '/contact-us' }">联系我们</router-link>
          </span>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'FooterComponent'
}
</script>

<style lang="scss" scoped>
.footer-placeholder {
  height: 260px;
}

.footer {
  background: #1C2034;
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 0;

  &__links {
    width: 255px;
    color: #FFFFFF;
    height: 128px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > img {
      width: 90px;
      height: 32px;
    }

    .icons {
      img {
        width: 24px;
        height: 24px;
        margin-right: 16px;
      }
    }
  }

  &__about {
    display: flex;
    ul {
      width: 255px;
      li {
        font-size: 14px;
        line-height: 30px;
        a {
          color: #FFFFFF;
        }
      }
    }
  }

  &__content {
    height: 210px;
    padding-top: 40px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    width: 1200px;
    margin: 0 auto;

    .reach-us {
      color: #FFFFFF;
      font-size: 16px;

      .reach-us__email {
        display: flex;
        align-items: center;
        font-size: 14px;
        margin-top: 24px;

        i {
          width: 20px;
          height: 18px;
          background: #FFFFFF;
          color: #0F1221;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;
        }
      }
    }
  }

  .copyright {
    background: #0F1221;

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50px;
      color: #86909C;

      a {
        display: inline-block;
        padding: 0 15px;
        color: #86909C;
      }
    }
  }
}
</style>
