import Vue from 'vue'
import VueRouter from 'vue-router'
import DefaultView from "@/layout/DefaultView"
import MemberView from "@/layout/MemberView"
import {tokenName} from "@/utils/request"
import IndexView from '../views/Index/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    name: 'DefaultView',
    component: DefaultView,
    children: [
      {
        path: '/',
        name: 'IndexView',
        component: IndexView,
      },
      {
        path: '/fqa',
        name: 'Fqa',
        component: () => import(/* webpackChunkName: "fqa" */ '../views/fqa/index')
      },
      {
        path: '/online-test',
        name: 'OnlineTest',
        component: () => import(/* webpackChunkName: "online-test" */ '../views/OnlineTest/index')
      },
      {
        path: '/contact-us',
        name: 'ContactUs',
        component: () => import(/* webpackChunkName: "contact-us" */ '../views/ContactUs/index')
      },
      {
        path: '/privacy-statement',
        name: 'PrivacyStatement',
        component: () => import(/* webpackChunkName: "privacy-statement" */ '../views/PrivacyStatement/index')
      },
      {
        path: '/service-agreement',
        name: 'ServiceAgreement',
        component: () => import(/* webpackChunkName: "service-agreement" */ '../views/ServiceAgreement/index')
      }
    ]
  },
  {
    path: '/member',
    name: 'Member',
    component: MemberView,
    children: [
      {
        path: 'index',
        name: 'MemberIndex',
        component: () => import(/* webpackChunkName: "member-index" */ '../views/Member/index'),
        meta:{
          title:"首页",
          show: true,
        }
      },
      {
        path: 'order',
        name: 'MemberOrder',
        component: () => import(/* webpackChunkName: "member-order" */ '../views/Member/Order/index'),
        meta:{
          title:"订单",
          show: true,
        }
      },
      {
        path: 'wallet-address',
        name: 'WalletAddress',
        component: () => import(/* webpackChunkName: "member-wallet-address" */ '../views/Member/WalletAddress/index'),
        meta:{
          title:"钱包",
          show: true,
        }
      },
      {
        path: 'top-up',
        name: 'TopUp',
        component: () => import(/* webpackChunkName: "member-top-up" */ '../views/Member/TopUp/index'),
        meta:{
          title:"套餐",
          show: true,
        }
      },
      {
        path: 'bill',
        name: 'Bill',
        component: () => import(/* webpackChunkName: "member-bill" */ '../views/Member/Bill/index'),
        meta:{
          title:"充值",
          show: true,
        }
      },
      {
        path: 'setting',
        name: 'Setting',
        component: () => import(/* webpackChunkName: "member-setting" */ '../views/Member/Setting/index'),
        meta:{
          title:"设置",
          show: true,
        }
      },
      {
        path: 'passwd',
        name: 'Passwd',
        component: () => import(/* webpackChunkName: "member-passwd" */ '../views/Member/Passwd/index'),
        meta:{
          title:"修改密码",
          show: true,
        }
      },
      {
        path: 'notify',
        name: 'Notify',
        component: () => import(/* webpackChunkName: "member-notify" */ '../views/Member/Notify/index'),
        meta:{
          title:"通知",
          show: true,
        }
      },
      {
        path: 'notify-detail',
        name: 'NotifyDetail',
        component: () => import(/* webpackChunkName: "member-notify-detail" */ '../views/Member/Notify/detail'),
        meta:{
          title:"通知详情",
          show: true,
        }
      }
    ]
  },
  {
    path: '/login',
    name: 'LoginView',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login/index')
  },
  {
    path: '/reg',
    name: 'RegView',
    component: () => import(/* webpackChunkName: "reg" */ '../views/Reg/index')
  },
  {
    path: '/two-factor-authentication',
    name: 'TwoFactorAuthentication',
    component: () => import(/* webpackChunkName: "reg" */ '../views/TwoFactorAuthentication/index')
  },
  {
    path: '/result',
    name: 'Result',
    component: () => import(/* webpackChunkName: "result" */ '../views/Result/index')
  },
  {
    path: '/online-result',
    name: 'OnlineTestResult',
    component: () => import(/* webpackChunkName: "online-test-result" */ '../views/OnlineTest/result')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

// 如果当前用户 token 没有，则直接跳转到登录解密
router.beforeEach((to, from, next) => {
  if (to.path.indexOf('/member') >= 0 && !window.localStorage.getItem(tokenName)) {
    next({ name: 'LoginView' })
  } else {
    next()
  }
})

export default router
