import axios from 'axios'
import {Loading} from 'element-ui'

let loadingInstance;
const successCode = 10000;
const tokenName = "token"

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

const getToken = () => {
    return window.localStorage.getItem(tokenName)
}

let axiosConfig = {
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: process.env.VUE_APP_BASE_API ? process.env.VUE_APP_BASE_API : '/',
    // 超时
    timeout: 15000,
    // 是否loading
    showLoading: false,
    // withCredentials: true,
}

// 创建axios实例
const service = axios.create(axiosConfig)

// request拦截器
service.interceptors.request.use(config => {
    //加载loading
    if (config.showLoading) {
        // const loading = this.$loading({
        //     lock: true,
        //     text: 'Loading',
        //     spinner: 'el-icon-loading',
        //     background: 'rgba(0, 0, 0, 0.7)'
        // });

        loadingInstance = Loading.service({
            lock: true,
            fullscreen: true,
            text: '正在加载', // 显示在加载图标下方的加载文案
            spinner: 'el-icon-loading', // 自定义加载图标类名
            background: 'rgba(0, 0, 0, 0.7)'
        });
    }

    let token = getToken()

    if (token) {
        config["headers"] = {Authorization: `Bearer ${token}`}
    }

    return config
}, error => {
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
    //关闭loading
    if (res.config.showLoading) {
        loadingInstance.close();
    }
    return res.data
})

const handleCloseLoading = () => {
    if (loadingInstance) {
        loadingInstance.close();
    }
}

export default service
export {
    handleCloseLoading,
    tokenName,
    loadingInstance,
    successCode
}