<template>
  <div class="member-view">
    <header-component :is-member-header="true"/>
    <div class="member-view__left">
      <el-menu :default-active="defaultActive" :collapse="isCollapse">
        <router-link :to="{ path: '/member/index' }">
          <el-menu-item index="1">
            <i class="nav-icon icon-index"></i>
            <span slot="title">首页</span>
          </el-menu-item>
        </router-link>
        <router-link :to="{ path: '/member/order' }">
          <el-menu-item index="2">
            <i class="nav-icon icon-order"></i>
            <span slot="title">订单</span>
          </el-menu-item>
        </router-link>
        <router-link :to="{ path: '/member/wallet-address' }">
          <el-menu-item index="3">
            <i class="nav-icon icon-wallet-address"></i>
            <span slot="title">钱包地址</span>
          </el-menu-item>
        </router-link>
        <router-link :to="{ path: '/member/top-up' }">
          <el-menu-item index="4">
            <i class="nav-icon icon-top-up"></i>
            <span slot="title">充值</span>
          </el-menu-item>
        </router-link>
        <router-link :to="{ path: '/member/bill' }">
          <el-menu-item index="5">
            <i class="nav-icon icon-bill"></i>
            <span slot="title">账单</span>
          </el-menu-item>
        </router-link>
        <router-link :to="{ path: '/member/setting' }">
          <el-menu-item index="6">
            <i class="nav-icon icon-setting"></i>
            <span slot="title">设置</span>
          </el-menu-item>
        </router-link>
        <i :class="isCollapse ? 'collapse-btn  el-icon-s-unfold' : 'collapse-btn el-icon-s-fold'"
           @click="isCollapse = !isCollapse"></i>
      </el-menu>
    </div>
    <div :class="isCollapse ? 'content-container close' : 'content-container'">
      <router-view/>
    </div>
  </div>
</template>

<script>
import Header from "@/layout/components/Header"
import {tokenName} from "@/utils/request"
import {mapGetters} from "vuex";

export default {
  name: 'MemberView',
  created() {
    this.fetchData()
  },
  components: {
    [Header.name]: Header,
  },
  computed: {
    ...mapGetters([
      'userInfo'
    ]),
    defaultActive: function () {
      const {path} = this.$route

      if (path.indexOf('order') >= 0) {
        return "2"
      }

      if (path.indexOf('wallet-address') >= 0) {
        return "3"
      }

      if (path.indexOf('top-up') >= 0) {
        return "4"
      }

      if (path.indexOf('bill') >= 0) {
        return "5"
      }

      if (path.indexOf('setting') >= 0) {
        return "6"
      }

      return "1";
    }
  },
  data() {
    return {
      isCollapse: false
    }
  },
  methods: {
    fetchData: async function () {
      const token = window.localStorage.getItem(tokenName)

      if (token) {
        const status = await this.$store.dispatch('Info', token)

        // 如果当前用户没有验证，则跳转到两步验证页面
        const user = this.$store.state.user.userInfo;
        if (!user || user.two_factor_recovery_status !== 1) {
          await this.$router.push({path: '/two-factor-authentication'})
          return;
        }

        if (!status) {
          await this.$router.push({path: '/login'})
        }
      }
    }
  }
}
</script>

<style lang="scss">
.member-view {
  &__left {
    padding-top: 61px;
    width: 200px;
    height: 100%;
    position: fixed;
    font-size: 0;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1001;
    overflow: hidden;
    box-sizing: border-box;

    .nav-icon {
      width: 18px;
      height: 18px;
      display: inline-block;
      margin-right: 18px;
    }

    .icon-index {
      background: url("../assets/images/member/nav-index.png");
      background-size: cover;
    }

    .is-active .icon-index {
      background: url("../assets/images/member/nav-index-active.png");
      background-size: cover;
    }

    .icon-order {
      background: url("../assets/images/member/nav-order.png");
      background-size: cover;
    }

    .is-active .icon-order {
      background: url("../assets/images/member/nav-order-active.png");
      background-size: cover;
    }

    .icon-wallet-address {
      background: url("../assets/images/member/nav-wallet-address.png");
      background-size: cover;
    }

    .is-active .icon-wallet-address {
      background: url("../assets/images/member/nav-wallet-address-active.png");
      background-size: cover;
    }

    .icon-top-up {
      background: url("../assets/images/member/nav-top-up.png");
      background-size: cover;
    }

    .is-active .icon-top-up {
      background: url("../assets/images/member/nav-top-up-active.png");
      background-size: cover;
    }

    .icon-bill {
      background: url("../assets/images/member/nav-bill.png");
      background-size: cover;
    }

    .is-active .icon-bill {
      background: url("../assets/images/member/nav-bill-active.png");
      background-size: cover;
    }

    .icon-setting {
      background: url("../assets/images/member/nav-setting.png");
      background-size: cover;
    }

    .is-active .icon-setting {
      background: url("../assets/images/member/nav-setting-active.png");
      background-size: cover;
    }

    .el-menu {
      height: 100%;
      border-right: 0;
    }

    .collapse-btn {
      position: absolute;
      right: 12px;
      bottom: 12px;
      font-size: 24px;
      color: #86909C;
      cursor: pointer;
    }
  }

  .content-container {
    transition: padding-left 0.4s;
    padding-left: 220px;
    padding-top: 20px;
    padding-right: 20px;

    &.close {
      padding-left: 74px;
    }
  }
}
</style>
